require('../css/basics.css');
require('../css/nav.css');
require('../css/style.css');
require('../css/contacts-box.css');
require('../css/animal-description.css');
require('../css/footer.css');
require('./mobile-menu.js');
require('./animal-description.js');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    if (location.hash) {
        const id = location.hash.slice(1);
        const element = document.getElementById(id);

        if (element) {
            const scrollTop =
                window.pageYOffset ||
                (document.documentElement || document.body.parentNode || document.body).scrollTop;
            const finalOffset = element.getBoundingClientRect().top + scrollTop;
            window.scrollTo({
                top: finalOffset,
                behavior: 'smooth'
            });
        }
    }

    function animateTlotBottom() {
        tlotBot2.start();
        tlotBot3.start();
    }

    function animateGreens5() {
        g5MainStem1.start();
    }

    function animateGreens1() {
        animateStem.start();
    }

    function animateGreens3() {
        g3b1.start();
        g3b2.start();
    }

    function animateGreens4() {
        g4stem.start();
    }

    // TLOT bottom animation
    const tlotBot2 = KUTE.fromTo(
        '#tlot-bottom-2',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 1600 }
    );
    const tlotBot3 = KUTE.fromTo(
        '#tlot-bottom-3',
        { draw: '100% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 800 }
    );

    // Greens-1 animation
    const animateStem = KUTE.fromTo(
        '#greens-1-stem',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const animateTopLeafStroke = KUTE.fromTo(
        '#greens-1-top-leaf-stroke',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );
    const animateTopLeafFill = KUTE.fromTo(
        '#greens-1-top-leaf-fill',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );

    const animateLeaf1Stem = KUTE.fromTo(
        '#greens-1-leaf-1-stem',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );
    const animateLeaf1Fill = KUTE.fromTo(
        '#greens-1-leaf-1-fill',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 300 }
    );

    const animateLeaf2Stem = KUTE.fromTo(
        '#greens-1-leaf-2-stem',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );
    const animateLeaf2Stroke = KUTE.fromTo(
        '#greens-1-leaf-2-stroke',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );
    const animateLeaf2Fill = KUTE.fromTo(
        '#greens-1-leaf-2-fill',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );

    const animateLeaf3Stem = KUTE.fromTo(
        '#greens-1-leaf-3-stem',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );
    const animateLeaf3Stroke = KUTE.fromTo(
        '#greens-1-leaf-3-stroke',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );
    const animateLeaf3Fill = KUTE.fromTo(
        '#greens-1-leaf-3-fill',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );

    const animateLeaf4Stem = KUTE.fromTo(
        '#greens-1-leaf-4-stem',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );
    const animateLeaf4Stroke = KUTE.fromTo(
        '#greens-1-leaf-4-stroke',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 100 }
    );
    const animateLeaf4Fill = KUTE.fromTo(
        '#greens-1-leaf-4-fill',
        { draw: '0% 0%', opacity: 0 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );

    animateStem.chain([animateTopLeafStroke, animateTopLeafFill]);

    animateTopLeafFill.chain([
        animateLeaf1Stem,
        animateLeaf2Stem,
        animateLeaf3Stem,
        animateLeaf4Stem
    ]);

    animateLeaf1Stem.chain([
        animateLeaf1Fill,
        animateLeaf2Fill,
        animateLeaf3Fill,
        animateLeaf4Fill
    ]);

    animateLeaf2Fill.chain([animateLeaf2Stroke, animateLeaf3Stroke, animateLeaf4Stroke]);

    // Greens-5 animation
    const g5MainStem1 = KUTE.fromTo(
        '#greens-5-stem-1',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5MainStem2 = KUTE.fromTo(
        '#greens-5-stem-2',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5stem3 = KUTE.fromTo(
        '#greens-5-stem-3',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5stem4 = KUTE.fromTo(
        '#greens-5-stem-4',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5stem5 = KUTE.fromTo(
        '#greens-5-stem-5',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5stem6 = KUTE.fromTo(
        '#greens-5-stem-6',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g5leaf1 = KUTE.fromTo(
        '#greens-5-leaf-1',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );
    const g5leaf2 = KUTE.fromTo(
        '#greens-5-leaf-2',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );
    const g5leaf3 = KUTE.fromTo(
        '#greens-5-leaf-3',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );
    const g5leaf4 = KUTE.fromTo(
        '#greens-5-leaf-4',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );
    const g5leaf5 = KUTE.fromTo(
        '#greens-5-leaf-5',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );
    const g5leaf6 = KUTE.fromTo(
        '#greens-5-leaf-6',
        { opacity: 0 },
        { opacity: 1 },
        { duration: 500 }
    );

    g5MainStem1.chain(g5MainStem2);
    g5MainStem2.chain([g5stem3, g5stem4, g5stem5, g5stem6]);

    g5stem3.chain([g5leaf1, g5leaf2, g5leaf3, g5leaf4, g5leaf5, g5leaf6]);

    // Greens-3 animation
    const g3b1 = KUTE.fromTo(
        '#g3-border1',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 1200 }
    );
    const g3b2 = KUTE.fromTo(
        '#g3-border2',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 1200 }
    );
    const g3c11 = KUTE.fromTo(
        '#g3-center11',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g3c12 = KUTE.fromTo(
        '#g3-center12',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );
    const g3c21 = KUTE.fromTo(
        '#g3-center21',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g3c22 = KUTE.fromTo(
        '#g3-center22',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 200 }
    );
    const g3c23 = KUTE.fromTo(
        '#g3-center23',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );

    const g3fill1 = KUTE.fromTo('#g3-fill1', { opacity: 0 }, { opacity: 1 }, { duration: 500 });
    const g3fill2 = KUTE.fromTo('#g3-fill2', { opacity: 0 }, { opacity: 1 }, { duration: 500 });

    g3b1.chain([g3c11, g3c12]);
    g3b2.chain([g3c21, g3c22, g3c23]);

    g3c12.chain(g3fill1);
    g3c22.chain(g3fill2);

    // Greens-4 animation
    const g4stem = KUTE.fromTo(
        '#g4-stem',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g4l1b = KUTE.fromTo(
        '#g4-l1b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 400 }
    );
    const g4l2b = KUTE.fromTo(
        '#g4-l2b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g4l3b = KUTE.fromTo(
        '#g4-l3b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 300 }
    );
    const g4l1f = KUTE.fromTo('#g4-l1f', { opacity: 0 }, { opacity: 1 }, { duration: 300 });
    const g4l2f = KUTE.fromTo('#g4-l2f', { opacity: 0 }, { opacity: 1 }, { duration: 600 });
    const g4l3f = KUTE.fromTo('#g4-l3f', { opacity: 0 }, { opacity: 1 }, { duration: 200 });

    const g4l4b = KUTE.fromTo(
        '#g4-l4b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g4l5b = KUTE.fromTo(
        '#g4-l5b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g4l6b = KUTE.fromTo(
        '#g4-l6b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g4l4f = KUTE.fromTo('#g4-l4f', { opacity: 0 }, { opacity: 1 }, { duration: 400 });
    const g4l5f = KUTE.fromTo('#g4-l5f', { opacity: 0 }, { opacity: 1 }, { duration: 500 });
    const g4l6f = KUTE.fromTo('#g4-l6f', { opacity: 0 }, { opacity: 1 }, { duration: 400 });

    const g4l7b = KUTE.fromTo(
        '#g4-l7b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g4l8b = KUTE.fromTo(
        '#g4-l8b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 500 }
    );
    const g4l9b = KUTE.fromTo(
        '#g4-l9b',
        { draw: '0% 0%', opacity: 1 },
        { draw: '0 100%', opacity: 1 },
        { duration: 600 }
    );
    const g4l7f = KUTE.fromTo('#g4-l7f', { opacity: 0 }, { opacity: 1 }, { duration: 400 });
    const g4l8f = KUTE.fromTo('#g4-l8f', { opacity: 0 }, { opacity: 1 }, { duration: 500 });
    const g4l9f = KUTE.fromTo('#g4-l9f', { opacity: 0 }, { opacity: 1 }, { duration: 400 });

    g4stem.chain([g4l1b, g4l2b, g4l3b]);

    g4l1b.chain([g4l1f, g4l2f, g4l3f, g4l4b, g4l5b, g4l6b]);

    g4l4b.chain([g4l4f, g4l5f, g4l6f, g4l7b, g4l8b, g4l9b]);

    g4l7b.chain([g4l7f, g4l8f, g4l9f]);

    dogEyeBlinky();

    function dogEyeBlinky() {
        const svgMorpheus = new SVGMorpheus('#tlot-dog-eye', {
            duration: 250,
            rotation: 'none'
        });

        const dot1 = document.getElementById('tlot-dog-eye-opened-dot-1');
        const dot2 = document.getElementById('tlot-dog-eye-opened-dot-2');

        setTimeout(() => animate(), 650);

        function animate() {
            dot1.style.display = 'none';
            dot2.style.display = 'none';
            svgMorpheus.to('tlot-dog-eye-closed');
            setTimeout(() => {
                svgMorpheus.to('tlot-dog-eye-opened');
                setTimeout(() => {
                    dot1.style.removeProperty('display');
                    dot2.style.removeProperty('display');
                }, 200);
                setTimeout(() => animate(), 3000);
            }, 800);
        }
    }

    function animatePercentages() {
        const charitiesBorder = document.getElementById('charities-border');
        charitiesBorder.style.removeProperty('opacity');

        const charitiesValue = document.querySelector('.pct-circle.for-charities .val');
        charitiesValue.style.removeProperty('display');

        const charitiesPct = charitiesValue.querySelector('.number');
        charitiesPct.textContent = '0';

        const expensesBorder = document.getElementById('expenses-border');
        expensesBorder.style.removeProperty('opacity');

        const expensesValue = document.querySelector('.pct-circle.for-expenses .val');
        expensesValue.style.removeProperty('display');

        const expensesPct = expensesValue.querySelector('.number');
        expensesPct.textContent = '0';

        KUTE.fromTo(
            charitiesBorder,
            { draw: '0% 0%' },
            { draw: '0 100%' },
            { duration: 1500 }
        ).start();
        KUTE.to(charitiesPct, { number: 80 }, { duration: 1500 }).start();

        KUTE.fromTo(
            expensesBorder,
            { draw: '0% 0%' },
            { draw: '0 100%' },
            { duration: 1500 }
        ).start();
        KUTE.to(expensesPct, { number: 20 }, { duration: 1500 }).start();

        KUTE.fromTo(
            '#dist-border-1',
            { draw: '0% 0%', opacity: 1 },
            { draw: '0 100%', opacity: 1 },
            { duration: 600 }
        )
            .chain(
                KUTE.fromTo(
                    '#dist-border-2',
                    { draw: '0% 0%', opacity: 1 },
                    { draw: '0 100%', opacity: 1 },
                    { duration: 300 }
                ).chain(
                    KUTE.fromTo(
                        '#dist-border-3',
                        { draw: '0% 0%', opacity: 1 },
                        { draw: '0 100%', opacity: 1 },
                        { duration: 600 }
                    )
                )
            )
            .start();
    }

    const introImage = document.querySelector('.intro .image-wrapper');
    const greens1 = document.querySelector('.greens-1');
    const greens3 = document.querySelector('.greens-3');
    const teamProfiles = document.querySelector('.team .profiles');
    const contactSection = document.querySelector('.section.contact');
    const distributionSales = document.querySelector('.distribution .sales');
    const intersections = new Map();

    function handleViewportAnimations() {
        onViewportIntersectOnce(introImage, animateTlotBottom);
        onViewportIntersectOnce(greens1, animateGreens1, { offsetBottom: 200 });
        onViewportIntersectOnce(greens3, animateGreens3);
        onViewportIntersectOnce(teamProfiles, animateGreens4);
        onViewportIntersectOnce(contactSection, animateGreens5);
        onViewportIntersectOnce(distributionSales, animatePercentages);
    }

    handleViewportAnimations();

    const scrollTopButton = document.querySelector('.scroll-top');

    window.addEventListener('scroll', (e) => {
        handleViewportAnimations();
        const scrollTop =
            window.pageYOffset ||
            (document.documentElement || document.body.parentNode || document.body).scrollTop;
        scrollTopButton.style.display = scrollTop > 1800 ? 'flex' : 'none';
    });

    scrollTopButton.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    // window.addEventListener('resize', e => console.log(e.target.innerWidth));

    function onViewportIntersectOnce(element, cb, opts = {}) {
        const intersected = intersections.get(element);
        if (intersected) {
            return;
        }

        if (typeof opts === 'function') {
            cb = opts;
        }

        const bounding = element.getBoundingClientRect();

        if (
            bounding.top >= -element.offsetHeight &&
            bounding.left >= -element.offsetWidth &&
            bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth) + element.offsetWidth &&
            bounding.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) +
                    element.offsetHeight -
                    (opts.offsetBottom || 0)
        ) {
            cb();
            intersections.set(element, true);
            return true;
        }
        return false;
    }

    const slider = new Slider({
        parent: '.traits .wrapper',
        slideArea: 'self',
        slides: '.box',
        nav: '.m-slider-nav'
    });

    const sliderTeam = new Slider2({
        parent: '.team .profiles',
        slideArea: '.wrapper',
        slides: '.page',
        nav: '.slider-nav'
    });

    if (isMobile()) {
        slider.slideTo();
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

class Slider {
    constructor(params) {
        this.parent = document.querySelector(params.parent);
        this.slideArea =
            params.slideArea === 'self' ? this.parent : this.parent.querySelector(params.slideArea);
        this.slides = Array.from(this.parent.querySelectorAll(params.slides));
        this.nav = this.parent.querySelector(params.nav);
        this.navItems = Array.from(this.nav.querySelectorAll(params.navItems || 'li'));
        this.activeIndex = 0;
        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;

        this.init();
    }

    init() {
        window.addEventListener('resize', (e) => this.slideTo());

        this.slideArea.addEventListener(
            'touchstart',
            (event) => {
                this.touchstartX = event.changedTouches[0].screenX;
                this.touchstartY = event.changedTouches[0].screenY;
            },
            false
        );

        this.slideArea.addEventListener(
            'touchend',
            (event) => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                this.handleSwipe(event);
            },
            false
        );

        this.navItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                const idx = this.navItems.indexOf(e.target);
                this.slideTo(idx);
            });
        });
    }

    handleSwipe(event) {
        var caseCaptured = false;
        const { touchstartX, touchstartY, touchendX, touchendY } = this;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe left
            caseCaptured = true;
            if (this.activeIndex < this.slides.length - 1) {
                this.slideTo(this.activeIndex + 1);
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;

            if (!this.activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            this.slideTo(this.activeIndex - 1);
        }

        if (caseCaptured) {
            event.preventDefault();
        }
    }

    slideTo(idx = null) {
        if (!this.isMobile()) {
            const firstSlide = this.slides[0];
            firstSlide.style.removeProperty
                ? firstSlide.style.removeProperty('margin-left')
                : firstSlide.style.removeAttribute('margin-left');
            return;
        }

        if (idx === null) {
            idx = this.activeIndex;
        }

        const reset = (item) => item.classList.remove('active');
        const slides = this.slides;
        const parent = this.parent;

        this.navItems.forEach(reset);
        slides.forEach(reset);
        slides[idx] && slides[idx].classList.add('active');
        this.navItems[idx] && this.navItems[idx].classList.add('active');
        this.activeIndex = idx;

        setTimeout(() => {
            const activeItem = slides[idx];
            const activeIdx = idx;
            const activeItemW = activeItem.offsetWidth;
            const parentW = parent.offsetWidth;
            const marginLeft =
                activeIdx === 0
                    ? (parentW / 2 - activeItemW / 2).toFixed()
                    : (function measureItemsBefore() {
                          let value = 0;
                          for (let i = 0; i < activeIdx; i++) {
                              value += slides[i].offsetWidth;
                          }

                          const margin =
                              parseInt(window.getComputedStyle(slides[0]).marginRight) *
                              (idx === slides.length - 1 ? 1 : 2);
                          const itemsWidth = value + margin * (activeIdx - 1);
                          const parentWidth = parent.offsetWidth / 2;
                          const x = parentWidth - (margin + activeItemW / 2);

                          return -(itemsWidth - x);
                      })();

            slides[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    isMobile() {
        const detector = document.querySelector('.mobile-detector');
        const styles = window.getComputedStyle(detector);
        return styles.getPropertyValue('display') === 'none';
    }
}

class Slider2 {
    constructor(params) {
        this.parent = document.querySelector(params.parent);
        this.slideArea = this.parent.querySelector(params.slideArea);
        this.slides = Array.from(this.parent.querySelectorAll(params.slides));
        this.nav = this.parent.querySelector(params.nav);
        this.navItems = Array.from(this.nav.querySelectorAll(params.navItems || 'li'));
        this.navButtonsParent = this.parent.querySelector(params.navButtons);
        this.navButtons = this.navButtonsParent
            ? Array.from(this.navButtonsParent.querySelectorAll(params.navButton || 'a'))
            : [];
        this.activeIndex = 0;
        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;
        this.init();
    }

    init() {
        window.addEventListener('resize', (e) => this.slideToActiveButton());

        this.slideArea.addEventListener(
            'touchstart',
            (event) => {
                this.touchstartX = event.changedTouches[0].screenX;
                this.touchstartY = event.changedTouches[0].screenY;
            },
            false
        );

        this.slideArea.addEventListener(
            'touchend',
            (event) => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                this.handleSwipe(event);
            },
            false
        );

        this.navItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                const idx = this.navItems.indexOf(e.target);
                this.slideTo(idx);
                this.slideToActiveButton();
            });
        });

        if (this.navButtonsParent) {
            this.navButtonsParent.addEventListener(
                'touchstart',
                (event) => {
                    this.touchstartX = event.changedTouches[0].screenX;
                    this.touchstartY = event.changedTouches[0].screenY;
                },
                false
            );

            this.navButtonsParent.addEventListener(
                'touchend',
                (event) => {
                    this.touchendX = event.changedTouches[0].screenX;
                    this.touchendY = event.changedTouches[0].screenY;
                    this.handleSwipe(event);
                },
                false
            );

            this.navButtons.forEach((item) => {
                function disablePointerEvents(children) {
                    for (let i = 0, ii = children.length; i < ii; i++) {
                        const child = children[i];
                        child.style.pointerEvents = 'none';
                        if (child.children.length) {
                            disablePointerEvents(child.children);
                        }
                    }
                }

                disablePointerEvents(item.children);

                item.addEventListener(
                    'click',
                    (e) => {
                        e.stopPropagation();
                        const idx = this.navButtons.indexOf(e.target);
                        this.slideTo(idx);
                        this.slideToActiveButton();
                    },
                    {}
                );
            });
        }
    }

    handleSwipe(event) {
        var caseCaptured = false;

        const { touchstartX, touchstartY, touchendX, touchendY } = this;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 50) {
            // Swipe left
            caseCaptured = true;
            if (this.activeIndex < this.slides.length - 1) {
                this.slideTo(this.activeIndex + 1);
                this.slideToActiveButton();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 50) {
            // Swipe right
            caseCaptured = true;

            if (!this.activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            this.slideTo(this.activeIndex - 1);
            this.slideToActiveButton();
        }

        if (caseCaptured) {
            event.preventDefault();
        }
    }

    slideTo(idx) {
        const reset = (item) => item.classList.remove('active');

        this.navItems.forEach(reset);
        this.navButtons.forEach(reset);
        this.slides.forEach(reset);

        this.slides[idx] && this.slides[idx].classList.add('active');
        this.navItems[idx] && this.navItems[idx].classList.add('active');
        this.navButtons[idx] && this.navButtons[idx].classList.add('active');
        this.activeIndex = idx;
    }

    slideToActiveButton() {
        if (!this.navButtons.length) {
            return;
        }

        if (!this.isMobile()) {
            const firstButton = this.navButtons[0];
            firstButton.style.removeProperty
                ? firstButton.style.removeProperty('margin-left')
                : firstButton.style.removeAttribute('margin-left');
            return;
        }

        const { navButtonsParent, navButtons } = this;

        setTimeout(() => {
            const activeItem = navButtons.find((x) => x.classList.contains('active'));
            const activeIdx = navButtons.indexOf(activeItem);
            const activeItemW = activeItem.offsetWidth;
            const parentW = navButtonsParent.offsetWidth;
            const marginLeft =
                activeIdx === 0
                    ? (parentW / 2 - activeItemW / 2).toFixed()
                    : (function measureItemsBefore() {
                          let value = 0;
                          for (let i = 0; i < activeIdx; i++) {
                              value += navButtons[i].offsetWidth;
                          }

                          const margin =
                              parseInt(window.getComputedStyle(navButtons[0]).marginRight) * 2;
                          const itemsWidth = value + margin * (activeIdx - 1);
                          const parentWidth = navButtonsParent.offsetWidth / 2;
                          const x = parentWidth - (margin + activeItemW / 2);

                          return -(itemsWidth - x);
                      })();

            navButtons[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    isMobile() {
        const detector = document.querySelector('.mobile-detector');
        const styles = window.getComputedStyle(detector);
        return styles.getPropertyValue('display') === 'none';
    }
}
