window.addEventListener('DOMContentLoaded', (e) => {
    const button = document.querySelector('.mobile-menu-button');
    button.addEventListener('click', (e) => {
        button.classList.toggle('open');
        document.querySelector('.top-nav').classList.toggle('open');
        document.body.classList.toggle('stop-scrolling');
        const contentWrapper = document.querySelector('.intro .content-wrapper');
        if (contentWrapper) {
            contentWrapper.style.removeProperty('transform');
        }
    });

    document.querySelectorAll('.top-nav ul.nav-buttons li > a').forEach((btn) => {
        btn.addEventListener('click', (e) => {
            const pathname = window.location.pathname.substring(1);

            if (
                !/^(privacy|terms|mint|howto)/i.test(pathname) &&
                !String(e.target.href).endsWith('.html') &&
                String(e.target.href).indexOf('shop.') === -1
            ) {
                e.preventDefault();
            }

            const id = e.target.href.slice(e.target.href.lastIndexOf('#') + 1);
            const element = document.getElementById(id);

            if (element) {
                const scrollTop =
                    window.pageYOffset ||
                    (document.documentElement || document.body.parentNode || document.body)
                        .scrollTop;
                const finalOffset = element.getBoundingClientRect().top + scrollTop;

                console.log('scrolling', finalOffset);
                window.scrollTo({
                    top: finalOffset,
                    behavior: 'smooth'
                });
            }

            const mobileNav = document.querySelector('.top-nav');
            if (mobileNav.classList.contains('open')) {
                mobileNav.classList.remove('open');
                document.querySelector('.mobile-menu-button').classList.remove('open');
                document.body.classList.remove('stop-scrolling');
            }
        });
    });

    const langDropdown = document.querySelector('.dropdown');

    if (langDropdown) {
        langDropdown.addEventListener('click', (e) => {
            e.target.closest('.dropdown').classList.toggle('expanded');
        });

        langDropdown.addEventListener('mouseover', (e) => {
            e.target.closest('.dropdown').classList.add('expanded');
        });

        langDropdown.addEventListener('mouseout', (e) => {
            e.target.closest('.dropdown').classList.remove('expanded');
        });
    }
});
