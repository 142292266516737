window.addEventListener('DOMContentLoaded', e => {

    const parent = document.querySelector('.animal-description');
    parent.addEventListener('click', e => {

        if (e.target.classList.contains('header')) {

            const descriptionBox = e.target.closest('.description-box');
            const isOpen = descriptionBox.classList.contains('open');

            parent.querySelectorAll('.description-box').forEach(item => item.classList.remove('open'));
            !isOpen && setTimeout(() => e.target.closest('.description-box').classList.add('open'), 250);
        }
    })
});
